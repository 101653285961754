body {
  background-image: url(../../public/bg.jpg);
}

.fab {
  font-family: "Font Awesome 5 Brands";
}

.data-form {
  width: 50%;
}
@media screen and (max-width: 575px) {
  .data-form {
    width: 100%;
  }
}

.social {
  padding: 10px;
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.form-svg {
  padding: 5px;
  color: rgb(64, 64, 64);
  width: 30px;
}

.w-error {
  font-weight: 600;
  color: red;
}

.heading {
  color: rgb(231, 231, 231);
  font-weight: 700;
}

.header_logo {
  background-image: url(../../public/header_logo.gif);
  height: 250px;
  width: 600px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.header_logo:hover {
  cursor: pointer;
}

.form {
  /* margin-left: 30%; */
  margin: auto;
}

.center {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
}

#g-recaptcha {
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center;
  display: inline-block;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.form-check-input {
  color: black;
}

.share-tweet:hover {
  cursor: pointer;
}

.primary-button {
  font-size: 22px;
  font-weight: 700;
  font-style: normal;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  background-color: transparent;
  color: rgb(0, 174, 255);
  width: auto;
  padding: 16px 20px;
  border-radius: 8px;
  border: 2px solid rgb(0, 174, 255);
}

.primary-button:disabled {
  color: silver;
  border-color: silver;
}
.text-center {
  text-align: center;
}

.g-recaptcha {
  display: inline-block;
}

@media screen and (max-width: 575px) {
  .recaptcha {
    transform: scale(0.77);
    -webkit-transform: scale(0.77);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
  }
}
